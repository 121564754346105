import produce from 'immer'
import { LOGIN, LOGOUT, SET_USER, UPDATE_USER } from './constants'

const initialState = {}

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOGIN:
        draft.user = action.user
        draft.token = action.token
        break
      case SET_USER:
        draft.user = action.user
        break
      case UPDATE_USER:
        draft.user = { ...draft.user, ...action.user }
        break
      case LOGOUT:
        return initialState
      default:
        break
    }
  })

export default reducer
