const LoadingPage = () => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: '0',
        left: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f0f2f5',
        overflow: 'hidden',
      }}
    >
      <svg
        width='64'
        height='64'
        viewBox='0 0 100 100'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_138_3391)'>
          <path
            d='M63.561 33.1345L76.7781 46.6484L87 36.4229L50.2239 -0.349785L40.0019 9.87219L53.279 23.1493L26.8878 49.4781L37.0526 59.6429L63.561 33.1345Z'
            fill='#3067F9'
          />
          <path
            d='M73.9252 49.6774L63.561 39.3132L37.1685 65.9378L23.6988 52.667L13.5339 62.8319L37.2519 86.1513L73.9252 49.6774Z'
            fill='#1B1464'
          />
          <path
            d='M50.1287 79.2634L39.8805 89.5116L50.1287 99.7598L60.3768 89.5116L50.1287 79.2634Z'
            fill='#1B1464'
          />
        </g>
        <defs>
          <clipPath id='clip0_138_3391'>
            <rect width='100' height='100' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default LoadingPage
