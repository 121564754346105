import loadable from '../util/functions/loadable'
import { CopyOutlined, HomeOutlined } from '@ant-design/icons'

const MainLayout = loadable(() => import('../layouts/MainLayout/MainLayout'))
const AuthLayout = loadable(() => import('../layouts/AuthLayout/AuthLayout'))
const Home = loadable(() => import('../views/Home/Home'))
const Tasks = loadable(() => import('../views/Tasks/Tasks'))
const Login = loadable(() => import('../views/Login/Login'))
const SignUp = loadable(() => import('../views/SignUp/SignUp'))
const ForgotPassword = loadable(() =>
  import('../views/ForgotPassword/ForgotPassword')
)
const UpdatePassword = loadable(() =>
  import('../views/UpdatePassword/UpdatePassword')
)

export const homePath = '/home'
export const tasksPath = '/tasks'
export const loginPath = '/login'
export const signUpPath = '/signup'
export const forgotPasswordPath = '/forgot-password'
export const updatePasswordPath = '/update-password/:token'

const homeRoute = {
  name: 'home',
  icon: <HomeOutlined />,
  path: homePath,
  layout: MainLayout,
  component: Home,
  guardianConfig: {
    protect: true,
  },
}

const tasksRoute = {
  name: 'tasks',
  icon: <CopyOutlined />,
  path: tasksPath,
  layout: MainLayout,
  component: Tasks,
  guardianConfig: {
    protect: true,
  },
}

const loginRoute = {
  hidden: true,
  path: loginPath,
  component: Login,
  layout: AuthLayout,
  guardianConfig: {
    redirectOnSession: true,
    pathToRedirect: homePath,
  },
}

const signUpRoute = {
  hidden: true,
  path: signUpPath,
  component: SignUp,
  layout: AuthLayout,
  guardianConfig: {
    redirectOnSession: true,
    pathToRedirect: homePath,
  },
}

const forgotPasswordRoute = {
  hidden: true,
  path: forgotPasswordPath,
  component: ForgotPassword,
  layout: AuthLayout,
  guardianConfig: {
    redirectOnSession: true,
    pathToRedirect: homePath,
  },
}

const updatePasswordRoute = {
  hidden: true,
  path: updatePasswordPath,
  component: UpdatePassword,
  layout: AuthLayout,
  guardianConfig: {
    redirectOnSession: true,
    pathToRedirect: homePath,
  },
}

export const navRoutes = [
  homeRoute,
  tasksRoute,
  loginRoute,
  signUpRoute,
  forgotPasswordRoute,
  updatePasswordRoute,
]
